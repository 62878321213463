<template>
	<div class="">
		<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
			<div class="tableConTable">
				<div class="tableConTop">
					<el-row>
						<el-col :span="5" class="tableConTopLeft">
							<h3>
								<!-- <el-button type="primary" round icon="el-icon-back" size="mini" @click="$router.push({ name: 'WhCustomerSkuInventoriesChangeStock' })">
									SKU<span>{{$t('i18nn_de53ab10322cd38a')}}</span>
								</el-button> -->
								<span class="tct_tit">SKU<span>{{$t('i18nn_46ed817ece2a5540')}}</span></span>
								<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
									<el-button type="warning" circle icon="el-icon-download" size="mini"
										@click="exportExcel()"></el-button>
								</el-tooltip>
								
							</h3>
						</el-col>
						<el-col :span="19" class="tableConTopRig">
							
							<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
							</el-button>
						</el-col>
					</el-row>
				</div>

				<div>
					<div class="filterCon">
						<ul class="filterConList">
							
							<li>
								<span>{{$t('hytxs0000060')}}</span>
								<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
							</li>
							<li>
								<span>{{$t('c944a6686d996ab3')}}</span>
								<whNoSelect @changeData="changWhNo"></whNoSelect>
							</li>
							<li>
								<span>SKU</span>
								<el-input type="text" v-model="filterData.goodsSku" size="small" clearable
									@keyup.enter.native="initData()" maxlength="50"
									:placeholder="'请输入(精确搜索)'" style="width: 180px;" />
							</li>
							<li>
								<span>{{$t('i18nn_f353b1ebbdc25be4')}}</span>
								<el-input type="text" v-model="filterData.keyword" size="small" clearable
									@keyup.enter.native="initData()" maxlength="50"
									:placeholder="$t('custom_enterfuzzysearch')" style="width: 180px;" />
							</li>

							<li>
								<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
									{{ $t('1e7246dd6ccc5539') }}
								</el-button>
							</li>
						</ul>
					</div>
				</div>

				<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
					:height="$store.state.tableMaxHeight2" @row-click="handleCurrentChange"
					@selection-change="handleSelectionChange" style="width: 100%" size="small">
					<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
						:label="$t('7b1c2b1adc920d9c')"></el-table-column>

					<el-table-column prop="cusName" :label="$t('hytxs0000060')" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="whNo" :label="$t('c944a6686d996ab3')"></el-table-column>
					<el-table-column prop="goodsSku" label="SKU"></el-table-column>
					
					<el-table-column prop="stock" :label="$t('i18nn_67d0b6c398583e40')"></el-table-column>
					

					<!-- <el-table-column prop="totalStock" :label="$t('0640e3814d107002')">
					</el-table-column> -->

					<!-- <el-table-column prop="lockStock" :label="$t('68b3bb1ef6931076')">
					</el-table-column> -->

					<!-- <el-table-column prop="currentStock" :label="$t('d91fd63094e82992')"></el-table-column> -->

					<el-table-column prop="inWhDate" :label="$t('i18nn_4647ba3fc60f0151')"></el-table-column>
					<!-- <el-table-column prop="inWhSource" :label="'inWhSource'"></el-table-column> -->
					<el-table-column prop="receiptNo" :label="$t('i18nn_6c86c295863f5218')"></el-table-column>
					<el-table-column prop="statusName" :label="$t('6cdece641436d7ab')">
						<template slot-scope="scope">
							{{ $Utils.i18nKeyText(scope.row,'statusName') }}
						</template>
					</el-table-column>
					<el-table-column prop="inWhSourceName" :label="$t('i18nn_61c33362d88eb0ba')">
						<template slot-scope="scope">
							{{ $Utils.i18nKeyText(scope.row,'inWhSourceName') }}
						</template>
					</el-table-column>
					
					<el-table-column prop="place" :label="$t('hytxs0000035')"></el-table-column>
					
					

					<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
						<template slot-scope="scope">
							<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
								<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
							</el-popover>
						</template>
					</el-table-column>
					
					
					<el-table-column prop="createTime" :label="$t('2594059036dfede5')" width="200">
						<template slot-scope="scope">
							<div>
								<div><span>{{$t('i18nn_e8564657bbe9ca53')}}</span>：{{scope.row.createTime}}</div>
								<div><span>{{$t('i18nn_e1911e9360047dcb')}}</span>：{{scope.row.updateTime}}</div>
							</div>
						</template>
					</el-table-column>

					<el-table-column :label="$t('93f5ca01b006206c')" width="180px" fixed="right">
						<template slot-scope="scope">
							<div>
								<!-- <el-button @click="openDet($event, scope.row, scope.$index)" type="primary" size="mini"
									icon="el-icon-view">{{$t('i18nn_7c99ec8184b2b9d1')}}</el-button> -->
								<el-button @click="changeSkuStock($event, scope.row, scope.$index)" type="warning"
									size="mini" icon="el-icon-edit">{{$t('i18nn_2140e9438c2d1633')}}</el-button>

							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="tableConPagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>


		<!-- 详情弹窗 -->
		<!-- <el-dialog :title="$t('i18nn_3def3d09b6b0b42a')" :close-on-click-modal="false" :visible.sync="dialogDetVisible"
			:top="'10px'" width="1000px">
			<div>
				<div class="tableCon" v-loading="loading_det" :element-loading-text="$t('47df8be257c59dde')">
					<div class="tableConTable">
						<el-table border :data="detData" style="width: 100%">
							<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
								:label="$t('7b1c2b1adc920d9c')"></el-table-column>
							
							<el-table-column prop="whNo" :label="$t('c944a6686d996ab3')"></el-table-column>
							<el-table-column prop="place" :label="$t('hytxs0000035')"></el-table-column>

							<el-table-column prop="goodsSku" label="SKU"></el-table-column>

							<el-table-column prop="totalStock" :label="$t('i18nn_de53ab10322cd38a')"></el-table-column>

							<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
								<template slot-scope="scope">
									<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
										<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
									</el-popover>
								</template>
							</el-table-column>

						</el-table>
					</div>
				</div>
			</div>
		</el-dialog> -->



		<!---->
		<el-dialog :title="$t('i18nn_b6ff7f85ee235684')" append-to-body :close-on-click-modal="false" :visible.sync="dialogStockVisible"
			width="1000px">
			<div style="">
				<el-card>
					<div>
						<el-form ref="StockData" :model="StockData" label-width="100px" v-loading="loading"
							:element-loading-text="$t('44bd089554515c4d')">
							<el-form-item :label="$t('i18nn_467c8054005f46e1')" required>
								<el-input-number size="" v-model="StockData.changeStock"></el-input-number>
								<!-- <span class="red">(正为增加，负为扣除)</span> -->
							</el-form-item>
							<!-- <el-form-item :label="$t('i18nn_6ab4144b540a8a6a')" required>
								<el-select clearable v-model="StockData.changeType">
									<el-option v-for="item in selectOption.wh_change_stock_type" :key="item.code"
										:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
										<div class="sel_option_s1" style="">
											<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
										</div>
									</el-option>
								</el-select>
							</el-form-item> -->
							<!-- <el-form-item :label="$t('15b3627faddccb1d')" required>
								<el-input type="textarea" size="" v-model="StockData.remark"></el-input>
							</el-form-item> -->
						</el-form>
					</div>
				</el-card>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogStockVisible = false">{{ $t('4e9fc68608c60999') }}
				</el-button>
				<el-button type="primary" @click="submitStock()">{{$t('ad4345dbaabe1479')}}</el-button>
			</div>
		</el-dialog>
		
		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="pagination"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhSkuStockLockPageList"
			:expHttpFilter="pageFilterData()"></whExcelCustom>

	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	
	import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';

	import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	
	import whExcelCustom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';
	
	export default {
		components: {
			// WhInGoods
			whNoSelect,
			cusSelFuzzy,
			whExcelCustom
		},
		// props: {
		// 	// mobile:"",
		// 	isSel: {
		// 		default: function() {
		// 			return false;
		// 		},
		// 		type: Boolean
		// 	}
		// },
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: "",
				excelOption: {},
				
				//上传图片
				// dialogFormVisible: false,
				// imgId: this.$store.getters.getUserInfo.id,
				// openRowData: {},
				// openRowIndex: {},
				// dialogDetVisible: false,
				// detData: [],
				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				dialogStockVisible: false,
				// StockData:{
				// 	"changeStock":"",
				// 	"remark":""
				// },
				StockData: {
					id:"",
					// "userId": "",
					// "goodsSku": "",
					"changeStock": "",
					// changeType: "",
					// whNo: "",
					// remark: ""
				},
				//打开入库选择框
				// dialogSelVisible: false,

				//选择
				currentSel: {},
				//表格数据
				//loading,表格数据
				loading_load: false,
				loading: false,
				loading_det: false,
				//表格数据
				tableData: [],
				//缓存，原数据
				// tableDataCatch: [],

				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//表格选择的数据
				multipleSelection: [],
				//查询，排序方式
				//"orderBy": "auditTime,fCon,goodsCon", //排序字段
				// cus_loading: false,

				selectOption: {
					wh_no: [],
					// cusList: [],
					wh_size_unit: [],
					wh_change_stock_type: [],
					// statusList: [
					//   {
					//     value: '',
					//     label: this.$t('16853bda54120bf1')
					//   },
					//   {
					//     value: '1',
					//     label: this.$t('hytxs0000027')
					//   },
					//   {
					//     value: '2',
					//     label: this.$t('i18nn_87619aa3fd09e8c2')
					//   }
					// ]
				},

				filterData: {
					orderBy: 'create_time', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// status: '',
					// plNo: '',
					// cusNo: '',
					// r_userId: '',
					whNo: '',
					userId: '',
					keyword: "",
					wh_size_unit: '',
					goodsSku: '',
					// goodsName: ''
				}
			};
		},
		// watch: {
		// 	$route(to, from) {
		// 		if (this.$route.query && this.$route.query.userId) {
		// 			this.filterData.userId = this.$route.query.userId;
		// 		}
		// 		this.initData();
		// 	}
		// },
		activated() {
			if (this.$route.query && this.$route.query.userId) {
				this.filterData.userId = this.$route.query.userId;
			}
			this.initData();
		},
		//创建时
		created() {
			// if(this.isSel){
			//   this.filterData.status = '2';
			// }
			// if (this.$route.query && this.$route.query.userId) {
			// 	this.filterData.userId = this.$route.query.userId;
			// }
			// this.initData();
		},
		//编译挂载前
		mounted() {
			//店铺默认可选数据
			// this.getCusListData('');
			// this.getWhNoData();
			// this.getDicData();
			//数据字典
			getDicData(['wh_change_stock_type'],
				(data)=>{
					this.selectOption.wh_change_stock_type = data['wh_change_stock_type'];
			});
		},
		methods: {
			initData() {
				//跳转到第一页
				this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];
				// if (this.filterData.userId) {
					this.getPageData();
				// } else {
				// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
				// }
				// 部门数据
				// this.getProvinceData();
				// this.getDetData("");
			},
			//导出 excel
			exportExcel() {
			
				let columns = [{
						title: this.$t('hytxs0000060'),
						key: 'cusName'
					},
					{
						title: this.$t('c944a6686d996ab3'),
						key: 'whNo'
					},
					{
						title: 'SKU',
						key: 'goodsSku'
					},
					{
						title: this.$t('i18nn_67d0b6c398583e40'),
						key: 'stock'
					},
					{
						title: this.$t('i18nn_4647ba3fc60f0151'),
						key: 'inWhDate'
					},
					{
						title: this.$t('i18nn_6c86c295863f5218'),
						key: 'receiptNo'
					},
					{
						title: this.$t('6cdece641436d7ab'),
						key: 'statusName'
					},
					{
						title: this.$t('i18nn_61c33362d88eb0ba'),
						key: 'inWhSourceName'
					},
					{
						title: this.$t('hytxs0000035'),
						key: 'place'
					},
					{
						title: this.$t('15b3627faddccb1d'),
						key: 'remark'
					},
					{
						title: this.$t('i18nn_e8564657bbe9ca53'),
						key: 'createTime'
					},
					{
						title: this.$t('i18nn_e1911e9360047dcb'),
						key: 'updateTime'
					},
				];
				let Data = this.tableData;
			
				// if (!this.filterData.userId) {
				// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
				// 	return;
				// }
				// if (!this.filterData.daterange || this.filterData.daterange.length<2) {
				// 	this.$message.warning(this.$t('i18nn_91e192ea5925de92'));
				// 	return;
				// }
			
				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = 'WhSkuStockLock';
				this.excelOption = {
					height: 20
				};
				this.excelOpenTime = new Date().getTime();
				// excelUtilsNew.exportExcel(columns, Data, 'WhCostStatistics',{height:20});
			},
			changCus(data) {
				console.log("changCus", data);
				// this.filterData.cusNo = data.cusNo;
				this.filterData.userId = data.userId;
				this.initData();
			},
			

			//作为组件选择行数据
			// selRow(event, row, index) {
			// 	event.stopPropagation();
			// 	this.$emit('selectRow', row);
			// },
			//打开图片上传
			// openUpLoadImg(row, index) {
			//   this.dialogFormVisible = true;
			//   this.openRowData = row;
			//   this.openRowIndex = index;
			//   this.$nextTick(() => {
			//     this.$refs.hyUpLoadImg1.openFile();
			//   });
			// },
			//清除图片
			// clearUpLoadImg(row,index){
			//   row.goodsImg = '';
			//   this.$set(this.tableData, index, row);
			// },

			//图片改变
			// changeImgUrl1(childValue) {
			//   console.log('changeImgUrl1', childValue);
			//   // childValue就是子组件传过来的值

			//   let row = this.openRowData;
			//   row.goodsImg = childValue;
			//   this.$set(this.tableData, this.openRowIndex, row);
			//   this.dialogFormVisible = false;
			//   // this.$set(this.tableDataCatch, this.openRowIndex, row);
			//   // this.myForm.imgList = [childValue];
			// },
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},
			//查看图片
			// openBigImg(event, imgsrc) {
			// 	event.stopPropagation();
			// 	console.log('openBigImg', imgsrc);
			// 	this.dialogImgVisible = true;
			// 	this.imgUrlBigShow = imgsrc;
			// },

			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},

			//客户模糊搜索
			// remoteMethod(query) {
			// 	if (query !== '') {
			// 		this.getCusListData(query);
			// 	} else {
			// 		this.selectOption.cusList = [];
			// 	}
			// },
			//客户模糊请求
			// getCusListData(key) {
			// 	this.cus_loading = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhCustomerPageList, {
			// 			keyword: key,
			// 			offset: 0,
			// 			limit: 50
			// 		})
			// 		.then(({ data }) => {
			// 			console.log('名称搜索，请求成功');
			// 			console.log(data);
			// 			
			// 			this.cus_loading = false;
			// 			if (200 == data.code) {
			// 				this.selectOption.cusList = data.rows;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_16abbd0c63637be3'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('名称搜索，请求失败');
			// 			this.$message.error('客户搜索数据，请求失败！');
			// 			this.cus_loading = false;
			// 		});
			// },

			// openDet(event, row) {
			// 	event.stopPropagation();
			// 	this.dialogDetVisible = true;
			// 	// this.detData = [row.packRecord];
			// 	let userId = this.filterData.userId;
			// 	let goodsSku = row.mySku.goodsSku;
			// 	if (row && userId && goodsSku) {
			// 		this.getDetPageData(userId, goodsSku);
			// 	} else {
			// 		this.$message.warning("未知的userId或goodsSku")
			// 	}

			// 	// console.log('packRecord',row.packRecord);
			// 	// this.$router.push({name:'WhInGoodsDetList2',query:{packId:row.id}});
			// 	// let title="";
			// 	// let key="";
			// 	// this.$store.dispatch('add_history_Tab_Data', {
			// 	//     title: title,
			// 	//     routeName: key,
			// 	//     query: {}
			// 	// });
			// },
			//SKU库存修改
			changeSkuStock(event, row) {
				event.stopPropagation();
				this.StockData = {
					id:row.id,
					// "userId": row.userId,
					// "goodsSku": row.goodsSku,
					"changeStock": "",
					// "changeType": "",
					// "whNo": row.whNo,
					// "remark": "",
				};
				this.dialogStockVisible = true;
			},
			submitStock() {
				let parm = this.StockData;

				this.postData(this.$urlConfig.WhSkuStockLockUnlock, parm, () => {
					this.initData();
					this.dialogStockVisible = false;
					this.$message.success(this.$t('9f30371831a98237'));
				});
			},
			//请求分页数据
			// getDetPageData(userId, goodsSku) {
			// 	let parm_goodsSku = encodeURIComponent(goodsSku);
			// 	this.loading_det = true;
			// 	this.detData = [];
			// 	this.$http
			// 		.get(this.$urlConfig.WhCusSkuStockDet + "/" + userId + "?goodsSku=" + parm_goodsSku, {

			// 		})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('列表，请求成功');
			// 			console.log(data);
			// 			
			// 			this.loading_det = false;
			// 			if (200 == data.code) {
			// 				//表格显示数据
			// 				this.detData = data.rows;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('列表，请求失败');
			// 			this.$message.error(this.$t('hytxs0000030'));
			// 			this.loading_det = false;
			// 		});
			// },
			//新增
			// addAction() {
			//   console.log('addAction');
			//   if(!!this.tableData[0] && !this.tableData[0].id){
			//     this.$alert('请先删除或保存第一条新增数据。', this.$t('cc62f4bf31d661e3'), {
			//       type: 'warning',
			//
			//     });
			//     return;
			//   }
			//   this.tableData.unshift({
			//     dataFlag: 'add',

			//     // "cusNo":this.$t('i18nn_64fed2851f79d875'),
			//     // "packageNo":this.$t('i18nn_7b27c6b2a3f93412'),
			//     // "goodsSku":this.$t('i18nn_e0c16ac094cc2bce'),
			//     // "goodsImg":this.$t('i18nn_877c5a0e44a0eb07'),
			//     // "goodsName":this.$t('i18nn_9eeccc6d6f1ff32f'),
			//     // "weightUnit":"11",//this.$t('i18nn_8e01866868930d09'),//this.$t('i18nn_8e01866868930d09'),
			//     // "goodsWeight":"1.122",//this.$t('i18nn_ad4b0047e16d89ae'),
			//     // "volumeUnit":"22",//this.$t('i18nn_57936a4767ded7f5'),//this.$t('i18nn_57936a4767ded7f5'),
			//     // "goodsLength":"1",//长",
			//     // "goodsWidth":"2",//宽",
			//     // "goodsHeight":"3",//高",
			//     // "goodsCount":"123",//入库件数",

			//     // "id":"",
			//     putWhNo: '',
			//     goodsSku: '',
			//     goodsName: '',
			//     goodsImg: '',
			//     pShelveCount: '', //实际上架数",
			//     pShelveVolAll: '', //this.$t('i18nn_bb0b485a98f17aea'),
			//     pShelveWeightAll: '', //this.$t('i18nn_4157ea5a8c62064b'),
			//     placeNo: '' //this.$t('hytxs0000035')
			//   });
			// },
			//删除新增
			// delAddAction(event, row, index) {
			//   event.stopPropagation();
			//   console.log('delAddAction', index);
			//   this.tableData.splice(index, 1);
			// },
			//编辑
			// openEdit(event, row, index) {
			//   event.stopPropagation();
			//   console.log('openEdit', row);
			//   row.dataFlag = 'edit';
			//   this.$set(this.tableData, index, row);
			// },
			//取消编辑，还原数据
			// editRefreshAction(event, row, index) {
			//   event.stopPropagation();
			//   console.log('editRefreshAction', row);
			//   // row.dataFlag = '';
			//   console.log('old_row_data11', this.tableDataCatch);
			//   // let old_row_data = row;
			//   let old_row_data_f = this.tableDataCatch.find(item => {
			//     return item.id == row.id;
			//   });
			//   let old_row_data = deepClone(old_row_data_f); //深拷贝

			//   // let old_row_data = Object.assign(old_row_data_f,{});//浅拷贝
			//   // let old_row_data_index = this.tableDataCatch.indexOf(item=>{
			//   //   return item.id == row.id;
			//   // });
			//   // let old_row_data = this.tableDataCatch[old_row_data_index];
			//   // .map(function (item) { //接收新数组
			//   //   return item * item;
			//   // });
			//   console.log('old_row_data', old_row_data);
			//   // this.tableData
			//   this.$set(this.tableData, index, old_row_data);
			//   // this.$forceUpdate();
			// },
			//保存
			// saveAction(event, row, index) {
			//   event.stopPropagation();
			//   console.log('saveAction', row);
			//   this.postData(this.$urlConfig.WhPlRecUpdate, [row], () => {
			//     if (row.id) {
			//       //不刷新数据，仅改变编辑状态
			//       row.dataFlag = null;
			//       this.$set(this.tableData, index, row);
			//       this.$set(this.tableDataCatch, index, row);

			//       // this.$alert('恭喜,编辑成功！', this.$t('cc62f4bf31d661e3'), {
			//       //   type: 'success',
			//       //
			//       // });
			//       this.$message.success('编辑成功！');
			//     } else {
			//       this.initData();
			//       // this.$alert('恭喜,新增成功！', this.$t('cc62f4bf31d661e3'), {
			//       //   type: 'success',
			//       //
			//       // });
			//       this.$message.success('新增成功！');
			//     }
			//   });
			// },
			//完成收货
			// completeAction(event, row) {
			//   event.stopPropagation();
			//   console.log('completeAction', row);
			//   let parm = [];

			//   if (!!row) {
			//     //单条
			//     parm = [row.id];
			//   } else {
			//     //多条
			//     let dataList = this.multipleSelection;
			//     if (dataList.length < 1) {
			//       this.$message.warning(this.$t('7b80e66b535a3732'));
			//       return;
			//     }

			//     let dataListParm = dataList.map(v => v.id);
			//     parm = dataListParm;
			//     // console.log('dataListParm', dataListParm);
			//     // let dataParm = {
			//     //   ids: dataListParm
			//     // };
			//   }

			//   this.postData(this.$urlConfig.WhShelvesComplete, parm, () => {
			//     this.initData();
			//     //  this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
			//     //   type: 'success',
			//     //
			//     // });
			//     this.$message.success(this.$t('9f30371831a98237'));
			//     // if(row.id){
			//     //   row.dataFlag = '';
			//     //   this.$alert('恭喜,编辑成功！', this.$t('cc62f4bf31d661e3'), {
			//     //     type: 'success',
			//     //
			//     //   });
			//     // } else {
			//     //   this.initData();
			//     //   this.$alert('恭喜,新增成功！', this.$t('cc62f4bf31d661e3'), {
			//     //     type: 'success',
			//     //
			//     //   });
			//     // }
			//   });
			// },

			//删除
			// delAction(event, row) {
			//   event.stopPropagation();
			//   console.log('delAction', row);
			//   let parm = [];

			//   if (!!row) {
			//     //单条
			//     parm = [row.id];
			//   } else {
			//     //多条
			//     let dataList = this.multipleSelection;
			//     if (dataList.length < 1) {
			//       this.$message.warning(this.$t('7b80e66b535a3732'));
			//       return;
			//     }

			//     let dataListParm = dataList.map(v => v.id);
			//     parm = dataListParm;
			//     // console.log('dataListParm', dataListParm);
			//     // let dataParm = {
			//     //   ids: dataListParm
			//     // };
			//   }

			//   this.postData(this.$urlConfig.WhGoodsDetDel, parm, () => {
			//     this.initData();
			//     //  this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
			//     //   type: 'success',
			//     //
			//     // });
			//     this.$message.success(this.$t('9f30371831a98237'));
			//     // if(row.id){
			//     //   row.dataFlag = '';
			//     //   this.$alert('恭喜,编辑成功！', this.$t('cc62f4bf31d661e3'), {
			//     //     type: 'success',
			//     //
			//     //   });
			//     // } else {
			//     //   this.initData();
			//     //   this.$alert('恭喜,新增成功！', this.$t('cc62f4bf31d661e3'), {
			//     //     type: 'success',
			//     //
			//     //   });
			//     // }
			//   });
			// },
			//分页的筛选项数据
			pageFilterData() {
				return {
					// states: this.filterData.status ? this.filterData.status : null,
					// plNo: this.filterData.plNo ? this.filterData.plNo : null,
					// cusNo: this.filterData.cusNo ? this.filterData.cusNo : null,
					userId: this.filterData.userId,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// sizeUnit: this.filterData.wh_size_unit ? this.filterData.wh_size_unit : null,
					// packageNo: this.filterData.packageNo ? this.filterData.packageNo : null,
					goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
				};
			},
			//请求分页数据
			getPageData() {

				let filterData = Object.assign({
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
				}, this.pageFilterData())

				this.loading_load = true;

				this.$http
					.put(this.$urlConfig.WhSkuStockLockPageList, filterData)
					.then(({
						data
					}) => {
						console.log('列表，请求成功');
						console.log(data);
						
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableData = data.rows;
							//取消编辑时调用
							//缓存，待编辑的数据
							// this.tableDataCatch = data.rows;//直接赋值为浅拷贝 concat也是浅拷贝
							// this.tableDataCatch = deepClone(data.rows);
							//当前数据总条数
							this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							// this.$message.success('订单列表，请求成功');
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a1761c0e88af75ff'));
						}
					})
					.catch(error => {
						console.log(error);
						console.log('列表，请求失败');
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},

			//提交信息
			postData(url, formData, callBack) {
				// let _this = this;
				this.loading = true;
				// formData.state = formData.state ? '1' : '0';

				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							// this.dialogFormVisible = false;
							// this.dialogAddFormVisible = false;
							// this.initData();
							callBack();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning',

							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning',

						});
					});
			},
			//查询仓库
			// getWhNoData() {
			// 	this.$http
			// 		.get(this.$urlConfig.whNoPageList)
			// 		.then(({ data }) => {
			// 			console.log('查询，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.rows) {
			// 				this.selectOption.wh_no = data.rows;
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = '查询仓库失败,请重试';
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log(this.$t('i18nn_323ee425eca7208c'));
			// 			this.$message.error('查询仓库失败,请重试！');
			// 		});
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_change_stock_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				this.selectOption.wh_change_stock_type = data.data['wh_change_stock_type'];
			// 				// this.selectOption.wh_car_tms_type = data.data['wh_car_tms_type'];
			// 				// this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		},

	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
